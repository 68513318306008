/**
 * OOI-specific experiments should be listed here.
 */
export const OOI_EXPERIMENTS = {
  /**
   * https://bo.wix.com/petri/experiments/3206705
   */
  PREVIEW_NAVIGATION_TO_POST_PAGE: 'specs.wixBlog.PreviewNavigationToPost',
  /**
   * https://bo.wix.com/guineapig/experiments/3199636
   */
  REMOVE_BLOGS_THIS_OOI: 'specs.wixBlog.RemoveBlogsThisOOI',
  /**
   * https://bo.wix.com/guineapig/experiments/3219328
   */
  REMOVE_BLOGS_THIS_EDITOR: 'specs.wixBlog.RemoveBlogsThisEditor',
  /**
   *https://bo.wix.com/petri/experiments/3221548
   */
  FIX_MOBILE_HEADER_STYLING: 'specs.wixBlog.mobileHeaderStyling',
};
